.form-title {
    font-size: 48px;
    line-height: 56px;
    color: #5f5f5f;
    margin-bottom: 30px;
}
.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .field-error {
        font-size: 13px;
        color: red;
    }
    label {
        font-weight: 500;
        font-size: 16px;
        color: #7e7e7e;
        margin-bottom: 8px;
    }
    input,
    select {
        background: #ffffff;
        border: 1px solid #e3e3e3;
        box-sizing: border-box;
        box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
        border-radius: 5px;
        padding: 10px;
        outline: none;
    }
}
.checkboxgroup {
    flex-direction: row;
    input {
        margin-right: 10px;
    }
}

.form-footer {
    display: flex;
    justify-content: center;
    .submit-button {
      background: #4085de;
      color: #ffffff;
      box-sizing: border-box;
    }
    .submit-button:disabled {
      background: rgba(10, 162, 241, 0.55);
    }

    .btn {
        border-radius: 6px;
        min-width: 150px;
        max-width: 200px;
        height: 42px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        cursor: pointer;
        margin: 0px 10px;
    }
  }