@include maxmobile {
    .subheader1 {
        width: fit-content !important;
    }
    .org-card-details {
        overflow: auto;
    }
    #boards-filters {
        display: flex;
        flex-direction: column;
    }
    
}

