@import "~antd/dist/antd.css";
@import "./assets/styles/index.scss";

.form-container {
  .form-title {
    font-size: 48px;
    line-height: 56px;
    color: #5f5f5f;
    margin-bottom: 30px;
  }
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .field-error {
      font-size: 13px;
      color: red;
    }
    label {
      font-weight: 500;
      font-size: 16px;
      color: #7e7e7e;
      margin-bottom: 8px;
    }
    input,
    select {
      background: #ffffff;
      border: 1px solid #e3e3e3;
      box-sizing: border-box;
      box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
      border-radius: 5px;
      padding: 10px;
      outline: none;
    }
  }

  .submit-button {
    background: #0d9fea;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    color: white;
    padding: 13px;
    width: 100%;
    border: 0px;
    cursor: pointer;
  }
  .submit-button:disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }
}

:focus-visible {
  outline: none;
}

pre {
  font-family: "Roboto";
  max-width: 190px;
  white-space: pre-wrap;
}

.ct-input-select {
  display: flex;
  align-items: center;
  gap: 5px;
  .ct-input-select-input {
    display: flex;
    align-items: center;
    position: relative;
    input[type="checkbox"] {
      width: 15px;
      height: 15px;
      appearance: none;
    }
    input[type="checkbox"] + span {
      display: block;
      width: 15px;
      height: 15px;
      position: absolute;
      top: 0;
      left: 0;
      border: 0.1px solid grey;
    }
    input[type="checkbox"]:checked + span::before {
      content: "";
      display: block;
      position: absolute;
      top: 1px;
      left: 50%;
      width: 5px;
      height: 9px;
      border: solid red;
      border-width: 0 2px 2px 0;
      transform: translateX(-50%) rotate(45deg);
    }
  }
}
.ct-main-select {
  label {
    min-height: 16px;
    line-height: 14px;
    display: flex;
    align-items: flex-start;
    color: #5f5f5f;
    span {
      font-size: 12px;
      line-height: 18px;
    }
  }
  input {
    margin-right: 5px;
  }
}
.ct-dropdown-optine {
  .org-tooltip-text-on-click {
    opacity: 0;
  }
  &.org-show-tooltip {
    .org-tooltip-text-on-click {
      opacity: 1;
    }
  }
}
