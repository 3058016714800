.subscription-success-container {
  text-align: center;
  min-height: 450px;
  z-index: 2;
  position: relative;
  .desc {
    font-size: 24px;
    margin-top: 40px;
    color: #5f5f5f;
    line-height: 1.5;
  }

  h1 {
    text-align: center;
    color: #5f5f5f;
    font-size: 40px;
    margin-top: 40px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  button {
    width: 249px;
    padding: 12px;
    border: 1px solid #0d9fea;
    box-sizing: border-box;
    box-shadow: 0px 0px 15px rgba(13, 159, 234, 0.15);
    border-radius: 5px;
    font-size: 16px;
    line-height: 19px;
    color: #0d9fea;
    text-align: center;
    margin-top: 6px;
    background: white;
    cursor: pointer;
  }

  img {
    margin-top: 50px;
  }
}

.vector-image {
  width: 100%;
  position: absolute;
  bottom: 0px;
}
