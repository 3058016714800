.forgot-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  .page-title {
    font-size: 48px;
    line-height: 56px;
    color: #5f5f5f;
  }
  .page-description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7e7e7e;
    padding: 10px 0px 30px 0px;
  }
  .form {
    width: 600px;
  }
  .infos {
    color: rgba(126, 126, 126, 1);
    margin-top: 30px;
    .content {
      display: flex;
      a {
        margin-left: 6px;
      }
    }
  }
  .password-strength{
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
    .elem {
      width: 32%;
      height: 4px;
    }
    &__good {
      div {
        background-color: #A1D38F;
      }
    }
    &__medium {
      div{
        background-color: #0d9fea;
      }
      div:last-child {
        background-color: #7e7e7e7a;
      }
    }
    &__weak {
      div:first-child{
        background-color: red;
      }
      div {
        background-color: #7e7e7e7a;
      }
    }
  }
}
