.Notifications {
    .categoryTitle {
        font-size: 16px;
        color: #7F7F7F;
        padding: 15px;
    }

    .elements {
        display: flex;
        flex-direction: column;
    }
    
}