.wrapper{
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
    height: fit-content;
    width: 400px;
    min-height: 400px;
    position: absolute;
    padding: 1.5rem 1.5rem;
    border-radius: 10px;
    background-color: #fff;
}
.login-form-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    background: #0d9fea1a;
    min-height: 100vh;
    width: 100%;
    overflow: auto;
  }