.SharedHeader {
    position: relative;
    z-index: 1;
    background: #4487DA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    color: white;
    min-height: 40px;
    .left {
        display: flex;
        align-items: center;
        img {
            margin-right: 10px;
            cursor: pointer;
        }
    }
    .rightButton {
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: -0.0500571px;
        color: #2968B8;
        background: #FFFFFF;
        border: 1px solid #4085DE;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 5px 10px;
        font-weight: 500;
    }
    .make {
        font-weight: bold;
        font-size: 1rem;
        margin-bottom: 0px;
    }
    .login {
        font-weight: 500;
        display: flex;
        gap: 1rem;
        align-items: center;
    }
    .loginBtn{
        all:unset;
        font-size: 1rem;
        padding: 0.3rem 1rem;
        border: 1px solid white;
        border-radius: 5px;
        cursor: pointer;
    }
    .signupBtn{
        all:unset;
        font-size: 1rem;
        padding: 0.3rem 1rem;
        border: 1px solid white;
        border-radius: 5px;
        cursor: pointer;
        background-color: white;
        color: #2968B8;
    }
}