.switch-button {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    span {
        margin-left: 15px;
        font-size: 16px;
        color: #7F7F7F;
    }
}
