@import "../../../assets/styles/variables/layouts";
@import "../../../assets/styles/mixin/queries";

.UpdatePassword {
    display: flex;
    justify-content: center;
    .formContainer {
        width: 300px;
        @include tablet {
            width: 400px;
        }
        .footer {
            display: flex;
            justify-content: flex-end;
            button:first-child {
                margin-right: 10px;
            }
        }
    }
}