.google-login-wrapper{
    display: flex;
    flex-direction: column;
}
.google-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.26);
    padding: 1rem;
    border-radius: 5px;
}
.container-header{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}
.google-btn{
    background-color: #0d9fea;
    width: 90%;
    border: 0px;
    padding: 0.5rem 0.5rem;
    border-radius: 5px;
    color: white;
    font-weight: 500;
}