.forgot-pwd{
    color: #0d9fea;
    cursor: pointer;
}
.different-login{
    color: #0d9fea;
    cursor: pointer;
}
.text-container{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 0.5rem;
    justify-content: space-between;
}