.CopyBoardComponent {
    .description {
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.07px;
        color: #000;
    }

    .checkboxs {
        padding-left: 20px;
    }

}