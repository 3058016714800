@import "../../../assets/styles/variables/layouts";
@import "../../../assets/styles/mixin/queries";

.infosContainer {
    @include tablet {
        display: flex;
        justify-content: space-evenly;
    }
    .imgContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
            //width: 125px;
            height: 125px;
            //border-radius: 50%;
        }
        .inputFile {
            display: none;
        }

        .labelButton {
            pointer-events: none;
            cursor: pointer;
            margin-top: 10px;
        }
    }

    .formContainer {
        min-width: 300px;
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        button:first-child {
            margin-right: 10px;
        }
    }
}
