.register-plans-container {
  margin-top: 100px;
  .page-title {
    margin: 40px 0px;
    color: #5F5F5F;
  }

  .back-to-login {
    font-weight: 500;
    font-size: 18px;
    color: #4085de;
    width: 287px;
    background: white;
    border: 1px solid #4085de;
    border-radius: 5px;
    margin-top: 50px;
    padding: 6px;
  }
  .register-plans-list {
    @media screen and (min-width: 1200px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .chosen-plan {
      height: 580px !important;
      transition: 0.4s ease height;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    .submit-button {
      border-radius: 6px;
      background: #4085de;
      color: #ffffff;
      box-sizing: border-box;
      width: 287px;
      height: 42px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      cursor: pointer;
      border: 0px;
    }
    .submit-button:disabled {
      background: rgba(10, 162, 241, 0.55);
    }
  }
}
.register-plan-item {
  width: 335px;
  height: 539px;
  margin: 25px;
  background: #ffffff;
  border: 1px solid #4085de;
  box-sizing: border-box;
  border-radius: 10px;
  transition: 0.3s ease height;
  .header {
    border-radius: 10px 10px 0px 0px;
    color: white;
    height: 56px;
    text-align: center;
    font-size: 24px;
    line-height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content {
    padding: 20px;
    .title {
      margin: 46px 0px;
      position: inherit !important;
      p {
        font-weight: 500;
        font-size: 48px;
        text-align: center;
        margin-bottom: 15px;
      }
    }
    .hint {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      letter-spacing: -0.0500571px;
      color: #5f5f5f;
    }
    .features {
      list-style-type: none;
      height: 210px;
      li {
        display: flex;
        padding: 10px 0px;
        font-size: 18px;
        line-height: 21px;
        color: #5f5f5f;
        svg {
          font-size: 18px;
          margin-right: 20px;
          color: #9b9b9b;
        }
      }
    }
    .submit-button {
      transition: 5s ease background-color;
      transition: 0.4s ease color;
    }
    .choose {
      border-radius: 6px;
      background: #ffffff;
      border: 1px solid #4085de;
      box-sizing: border-box;
      width: 100%;
      height: 42px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      cursor: pointer;
    }
  }
}

.register-email-check {
  .image-container-blue {
    text-align: center;
  }

  p {
    line-height: 32px;
    color: #5f5f5f;
    text-align: left;
    mark {
      background: white;
      color: #0D9FEA;
      cursor: pointer;
    }
    b {
      color: #5F5F5F;
      font-weight: 600;
    }
  }
}
