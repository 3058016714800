.ant-layout-content {
    margin: 0px;
    background-color: white;
}
.left-site-layout {
    background-color: #2E3C58;
    &_logo {
        width: 100%;
        font-size: 20px;
        font-weight: 700;
        text-align: justify;
        display: flex;
        padding: 16px;
        color: white;
    }
}

.site-layout_header {
    background-color: #2E3C58;
}