@import "../../assets/styles/mixin/queries";
@import "../../assets/styles/variables/layouts";

.modal {
    border-radius: 20px;
    overflow: hidden;
  @include tablet {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .backdrop {
    background: #4e4e4e;
    opacity: 0.4;
  }
  .paper {
    background-color: white;
    overflow: auto;
    @include tablet {
      width: 700px;
      border-radius: 20px;
      max-height: 600px;
      padding: 20px 30px;
    }

    @include desktop {
      max-height: 960px;
      border-radius: 20px;
      padding: 20px 30px;
    }

    @include maxmobile {
      width: 100%;
      width: calc(100% - 20px);
      height: 100vh;
      padding: 10px;
    }

    .header {
      display: flex;
      justify-content: space-between;
      h1 {
        font-weight: 400;
      }
      .close {
        color: #7f7f7f;
        font-size: 18px;
        cursor: pointer;
      }
    }
  }
}
