@import "../../../../assets/styles/variables/layouts";
@import "../../../../assets/styles/mixin/queries";
@import "../../../../assets/styles/variables/colors";

.PaymentMethod {
    border: 2px solid #F3F3F3;
    box-sizing: border-box;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    width: 150px;
    height: fit-content;
    color: #7F7F7F;
    font-size: 12px;
    .header {
        background: #F3F3F3;
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.02);
        border-radius: 5px 5px 0px 0px;
        height: 30px;
        font-size: 14px;
        line-height: 12px;
        display: flex;
        align-items: center;
        padding: 0px 10px;
    }
    .content {
        padding: 10px;
        .dateAndType {
            display: flex;
            justify-content: space-between;
            padding: 10px 0px;
        }
    }
    .updatePaymentMethod {
        color: $main-blue-color;
        border-top: 2px solid #F3F3F3;
        text-align: center;
        padding: 10Px;
    }
}

.nextPayment {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    span:first-child{
        font-size: 14px;
        color: $main-grey-color;
        margin-bottom: 5px;
    }
    span:last-child {
        font-size: 12px;
    }
}
