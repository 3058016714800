@mixin maxmobile {
	@media only screen and (max-width: #{$layout_bp_mobile}) {
		@content;
	}
} 

@mixin mobile {
	@media only screen and (min-width: #{$layout_bp_mobile}) {
		@content;
	}
} 

@mixin tablet {
	@media only screen and (min-width: #{$layout_bp_tablet}) {
		@content;
	}
}

@mixin desktop {
	@media only screen and (min-width: #{$layout_bp_desktop}) {
		@content;
	}
}

@mixin desktopHd {
	@media only screen and (min-width: #{$layout_bp_desktop_hd}) {
		@content;
	}
}

@mixin desktopHdPlus {
	@media only screen and (min-width: #{$layout_bp_desktop_hd_plus}) {
		@content;
	}
}

@mixin desktopMac {
	@media only screen and (min-width: #{$layout_bp_desktop_mac}) {
		@content;
	}
}
