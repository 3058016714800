
.header-heading{
    font-size: 1rem;
    margin-top: 0.5rem;
    font-weight: 600;
    color: #0d9fea;
}
.header-description{
    color: #0d9fea;
    margin-top: -0.5rem;
}

.social-icon-container{
    width: 100%;
    display: flex;
    gap: 5px;
    justify-content: center;
}
.other-login-text{
    font-weight: 600;
    text-align: center;
    margin-top: 1rem;
}
.social-logo{
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 0.4rem;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 3px;
    padding: 0.4rem 0.75rem;
    color: #42526e;
    max-width: 165px;
    width: 100%;
    border: 1px solid #e3e3e3;
    background-color: #f4f5f7;
    box-shadow: 0 1px 1px 0 #091e4240, 0 0 1px 0 #091e424f;
}