.forgot-password-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  .page-title {
    font-size: 48px;
    line-height: 56px;
    color: #5f5f5f;
  }
  .page-description {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #7e7e7e;
    padding: 30px 0px;
  }
  .form {
    width: 600px;
  }
  .infos {
    color: rgba(126, 126, 126, 1);
    margin-top: 30px;
    .content {
      display: flex;
      a {
        margin-left: 6px;
      }
    }
  }
}
