.email-error{
    margin-top: 0.5rem;
}
.privacy-text{
    font-size: 0.8rem;
}
.privacy-text a{
    color: #0d9fea;
}
.signup-button{
    width: 100%;
    background-color: #0d9fea;
    border: none;
    padding: 0.5rem;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 0.5rem;
}
.form-error{
    color: red;
}
.form-field-container{
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}
.input-field{
    background: #ffffff;
    border: 1px solid #e3e3e3;
    box-sizing: border-box;
    box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
    border-radius: 5px;
    padding: 10px;
    outline: none;
}
.form-label{
    font-weight: 500;
    font-size: 16px;
    color: #7e7e7e;
    margin-bottom: 8px;
}
.login-text{
    text-align: center;
    margin-top: 0.5rem;
}