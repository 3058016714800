@import "../../assets/styles/mixin/queries";
@import "../../assets/styles/variables/layouts";

.tabsContainer{
    display: flex;
    @include maxmobile {
        flex-direction: column;
    }

    @include tablet {
        border-bottom: 2px solid #F3F3F3;
    }
    
    .element {
        min-width: 130px;
        text-align: center;
        padding: 14px 10px;
        border: 2px solid #F3F3F3;
        border-right-width: 0px;
        cursor: pointer;
        @include tablet {
            border-bottom-width: 0;
        }
    }

    .element:last-child {
        border-right-width: 2px;
    }

    .current {
        border-top-color: #0D9FEA;
        color: #0D9FEA;
    }
}