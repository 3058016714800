.profile-plan {
    width: 300px;
    height: 450px;
    margin: 0px 10px;
    background: #ffffff;
    border: 1px solid #4085de;
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: 20px;;
    .header {
        border-radius: 10px 10px 0px 0px;
        color: white;
        height: 46px;
        text-align: center;
        font-size: 24px;
        line-height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content {
      .title {
        margin: 30px 0px;
        position: inherit !important;
        p {
          font-weight: 500;
          font-size: 48px;
          text-align: center;
          margin-bottom: 15px;
        }
      }
      .hint {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        letter-spacing: -0.0500571px;
        color: #5f5f5f;
      }
      .features {
        list-style-type: none;
        li {
          display: flex;
          padding: 10px 0px;
          font-size: 18px;
          line-height: 21px;
          color: #5f5f5f;
          svg {
            font-size: 18px;
            margin-right: 20px;
            color: #9b9b9b;
          }
        }
      }
  
      .choose {
        border-radius: 6px;
        background: #ffffff;
        border: 1px solid #4085de;
        box-sizing: border-box;
        width: 100%;
        height: 42px;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        cursor: pointer;
      }
    }
  }
