.email-error{
    margin-top: 0.5rem;
}
.privacy-text{
    font-size: 0.8rem;
}
.privacy-text a{
    color: #0d9fea;
}
.signup-button{
    width: 100%;
    background-color: #0d9fea;
    border: none;
    padding: 0.5rem;
    border-radius: 4px;
    color: white;
    font-weight: 600;
    font-size: 1rem;
}
.form-error{
    color: red;
}
.create-account{
    text-align: center;
    margin-top: 0.5rem;
}