.btn {
    padding: 10px !important;
    font-size: 14px !important;
    border-radius: 5px;
}
.default-button {
    min-width: 100px;
    border: 1px solid $main-blue-color;
    color: $main-blue-color;
    padding: 5px 10px;
    background-color: white;
}

.submit-button {
    background: #0D9FEA;
    color: white;
    min-width: 120px;
    border: 0px;
    cursor: pointer;
  }
  .submit-button:disabled {
    color: rgba(255, 255, 255, 0.3);
    cursor: not-allowed;
  }