@include maxmobile {
    .subheader1 {
        width: fit-content !important;
        .logo-mobile {
            display: block  !important;
            width: 32px  !important;
            height: 37px  !important;
        }
        .logo-large {
            display: none !important;
        }
    }
    .org-card-details {
        overflow: auto;
    }
    #boards-filters {
        display: flex;
        flex-direction: column;
    }
    
}

@include mobile {
    .subheader1 {
        display: flex !important;
        .logo-mobile {
            display: none !important;
        }
        .logo-large {
            display: block !important;
        }
    }
}