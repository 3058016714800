.payment-form {
  display: flex;
  min-height: 100vh;
  width: 100%;
  overflow: auto;
  .infos {
    background-color: #0d9fea;
    width: 50%;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    .logo-container {
      width: 100%;
    }
    .header {
      padding: 20px;
      width: 400px;
      svg {
        font-size: 30px;
        font-weight: bold;
        color: white;
        cursor: pointer;
      }
    }
    .content {
      width: 400px;
      .cards {
        display: flex;
      }
      .card {
        padding: 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 16px;
        background: #ffffff;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        border-radius: 15px;
        width: 178.8px;
        .infos-container {
          margin-left: 20px;
          .hint {
            color: #575757;
            font-size: 13px;
          }
          .price {
            font-weight: bold;
            font-size: 24px;
            color: #5f5f5f;
            display: flex;
            align-items: center;
          }
        }

        svg {
          font-size: 30px;
          color: black;
        }
        .discount {
          text-align: center;
          padding: 3px;
          border-radius: 5px;
          background: #ccedff;
          color: #016da8;
          font-size: 10px;
        }
      }

      .for {
        font-weight: 500;
        font-size: 16px;
        color: white;
      }

      .features{
        list-style-type: none;
        color: #FFFFFF;
        li {
          margin: 15px 0px;
        }
        svg {
          font-size: 18px;
          margin-right: 20px;
          color: #FFFFFF;
        }
      }

      .plan {
        display: flex;
        justify-content: space-between;
        color: white;
        font-size: 16px;
        line-height: 19px;
        margin: 15px 0px 5px;
      }
      .quantity {
        display: inline-grid;
        grid-template-columns: 40px 30px 150px;
        color: white;
        font-size: 13px;
      }
    }
  }
  .form {
    width: 50%;
    min-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0px 0px 28px;
    .header {
      padding: 20px;
      width: 400px;
      text-align: right;
      svg {
        font-size: 30px;
        font-weight: bold;
        color: #7f7f7f;
      }
    }
    .divider {
      font-size: 11px;
      line-height: 13px;
      color: #9b9b9b;
    }
    .content {
      width: 400px;
      .apple-pay {
        background: #000000;
        width: 100%;
        color: white;
        padding: 7px;
        border-radius: 8px;
        font-size: 23px;
        line-height: 27px;
        svg {
          position: relative;
          top: 2px;
        }
      }
      .divider-container {
        margin-top: 20px;
        margin-bottom: 20px;
        .border {
          border-color: #9b9b9b;
        }
      }
    }
  }

  .form-container {
    .billing-addresss {
      input,
      select {
        border-radius: 0px;
        border-bottom: 0px;
      }
      select:first-child,
      input:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      input:last-child,
      input:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-bottom: 1px solid #e3e3e3;
      }
      .city-zip {
        input {
          border-radius: 0px;
          width: 50%;
        }
      }
      .cardNumber{
        display: flex;
        justify-content: space-between;
        background-color: rgba(255, 255, 255, 1);
        background: #ffffff;
        border: 1px solid #e3e3e3;
        box-sizing: border-box;
        box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
        border-radius: 5px;
        padding: 8px;
        outline: none;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom: 0px;
        .StripeElement {
          padding: 0px;
          border: 0px;
          border-radius: 0px;
          width: 250px;
        }
      }
    }
    .card-infos {
      .city-zip {
        display: flex;
        .StripeElement {
          background-color: rgba(255, 255, 255, 1);
          background: #ffffff;
          border: 1px solid #e3e3e3;
          box-sizing: border-box;
          box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.02);
          border-radius: 5px;
          padding: 8px;
        }
        .StripeElement:first-child {
          border-bottom-left-radius: 5px !important;
          border-bottom: 1px solid #e3e3e3 !important;
          border-radius: 0px;
          width: 50%;
        }
        .StripeElement:last-child {
          border-bottom-right-radius: 5px !important;
          border-radius: 0px;
          width: 50%;
        }
      }
    }
  }
  .submit-button {
    background: #0d9fea;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    color: white;
    padding: 13px;
    width: 100%;
    border: 0px;
  }
  .submit-button:disabled {
    color: rgba(255, 255, 255, 0.3);
  }
  .description {
    margin-top: 20px;
    text-align: center;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: #9b9b9b;
  }
}
